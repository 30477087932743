import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledCard = styled.div`
  border: solid 1px black;
  background-color: white;
  width: 12em;
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0.5em 0;
    padding: 0;
  }
`
const StyledPic = styled.img`
  width: 90%;
`
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
const StyledSpan = styled.span`
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;
  &:hover {
    border-color: #2a3546;
  }
`
const StoryCard = ({ title, slug, cardpic }) => {
  return (
    <StyledLink to={"/stories/" + slug}>
      <StyledCard>
        <StyledPic
          src={require(`../../images/stories/${cardpic}`)}
          alt={title}
        />
        <StyledSpan>{title}</StyledSpan>
      </StyledCard>
    </StyledLink>
  )
}

export default StoryCard
