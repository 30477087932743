import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import stories from "../json/stories.json"
import StoryCard from "../components/storiespage/StoryCard"
import Div8095 from "../components/utils/Div8095"
import styled from "styled-components"
import ContainerDiv from "../components/utils/ContainerDiv"
import HeadingCard from "../components/utils/HeadingCard"

const StyledFlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Stories = () => {
  const x = stories.sort((a, b) => (a.slug < b.slug ? -1 : 1))
  const storyList = x.map((e, i) => {
    return (
      <StoryCard key={i} cardpic={e.cardpic} title={e.fullname} slug={e.slug} />
    )
  })

  return (
    <Layout>
      <SEO title={"Stories"} />
      <ContainerDiv bg="#653289">
        <Div8095>
          <HeadingCard>
            Stories of Resilience, Courage, Determination, Transformation
          </HeadingCard>
          <StyledFlexBox>{storyList}</StyledFlexBox>
        </Div8095>
      </ContainerDiv>
    </Layout>
  )
}

export default Stories
